import Vue from "vue";
import Router from "vue-router";

// Containers
const TheContainer = () => import("@/containers/TheContainer");
const LanguageScore = () => import("@/containers/CandidateProfile/LanguageTestScore")
const AddCandidate = () => import("@/containers/CandidateDetails/AddCandidate")

// Pages
const RegisterViaMail = () => import("@/pages/RegisterViaMail");
const CandidateList = () => import("@/pages/CandidateList");
const Register = () => import("@/pages/Register");
const Home = () => import("@/pages/Home");
const NewAddCandidate = () => import("@/pages/NewAddCandidate");
const CandidateProfile = () => import("@/pages/CandidateProfile/CandidateProfile.vue");
const AddHospitalAdmin = () => import("@/pages/AddHospitalAdmin");
const AddHospital = () => import("@/pages/AddHospital");
const HospitalList = () => import("@/pages/HospitalList");
const HospitalAdminList = () => import("@/pages/HospitalAdminList");
const Dashboard = () => import("@/pages/Dashboard");
const CandidateDetails = () => import("@/pages/CandidateDetailsTalentfind");
const JobBoard = () => import("@/pages/JobBoard");
const JobBoardStm = () => import("@/pages/JobBoardStm");
const JobBoardDetail = () => import("@/pages/JobBoardDetail");
const JobListDetail = () => import("@/pages/Jobs/JobListDetail");
const faq = () => import("@/views/pages/Faq");
const AddHospitalRecruiters = () => import("@/pages/AddHospitalRecruiters");
const HospitalRecruitersList = () => import("@/pages/HospitalRecruitersList");
const AddFacility = () => import("@/pages/AddFacility");
const AddFacilityDetail = () => import("@/pages/AddFacilityDetails");
const FacilityList = () => import("@/pages/FacilityList");
const JobCandidatesStatus = () => import("@/pages/JobCandidatesStatus/JobCandidatesStatus")
const ListJob = () => import("@/pages/Jobs/ListJob");
const ListJobNew = () => import("@/pages/Jobs/ListJobNew");
const JobAllocation = () => import("@/pages/JobAllocation")
const AddFacilityUser = () => import("@/pages/AddFacilityUser");
const NewCandidateList = () => import("@/pages/NewCandidateList");
const AddJobs = () => import("@/pages/Jobs/AddJob");
const EditJob = () => import("@/pages/Jobs/EditJob");
const ListJobTemplates = () => import("@/pages/JobTemplates/ListJobTemplates");
const UploadOrganisationPictures = () => import("@/pages/UploadOrganisationPictures");
const SPCDashboard = () => import("@/pages/SPCDashboard");
const AddCourse = () => import("@/pages/Courses/AddCourse");
const ListCourse = () => import("@/pages/Courses/ListCourse");
const ViewCourse = () => import("@/pages/Courses/CourseView");
const AddCourseTemplate = () => import("@/pages/CourseTemplate/AddCourseTemplate");
const AITemplate = () => import("@/pages/AITemplate");
const Assessment = () => import("@/pages/Assessment/Assessment");


const AddCustomer = () => import("@/pages/AddCustomer");
const CustomerList = () => import("@/pages/CustomerList");
const RecruiterDashBoard = () => import("@/pages/RecruiterDashBoard");
const JobListPreview = () => import("@/pages/Jobs/JobDetailsTalentFindNew");
const AddOrganisation = () => import("@/pages/AddOrganisation");
const AddChildOrganisation = () => import("@/pages/AddChildOrganisation");
const OrganisationList = () => import("@/pages/OrganisationList");
const AddContactUser = () => import("@/pages/AddContactUser");
const ContactUserList = () => import("@/pages/ContactUserList");
const ActivityList = () => import("@/pages/ActivityList/ActivityList");
const CandidateReviewerReviewActivity = () => import("@/pages/ActivityList/CandidateReviewer/ReviewActivity");
const JobAuthoriserReviewActivity = () => import("@/pages/ActivityList/JobAuthoriser/ReviewActivity");
const CandidateInterviewerReviewActivity = () => import("@/pages/ActivityList/CandidateInterviewer/ReviewActivity");
const CandidateInterviewerInterviewActivity = () => import("@/pages/ActivityList/CandidateInterviewer/InterviewActivity")
const RecruiterEmailMessages = () => import("@/pages/RecruiterEmailMessages");
const CandidateScheduleInterview = () => import("@/pages/ActivityList/Candidate/InterviewActivity");
const EmailConfiguration = () => import("@/pages/EmailConfiguration");
const NotificationConfiguration = () => import("@/pages/NotificationConfiguration");
const ManagementReport = () => import("@/pages/ManagementReport");
const PreRegisterCampaign = () => import("@/pages/Campaign/PreRegisterCampaign")
const CampaignRegister = () => import("@/pages/CampaignRegister");
const CampaignForm = () => import("@/pages/CampaignForm");
const RegisterCampaign = () => import("@/pages/Campaign/RegisterCampaign");
const CampaignLogin = () => import("@/pages/Campaign/CampaignLogin");
const CreateProfilePage = () => import("@/pages/ProfilePage/createProfilePage");
// Views

// Views - Pages
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
const Login = () => import("@/views/pages/Login");
const Forgot = () => import("@/views/pages/Forgot");
const Reset = () => import("@/views/pages/Reset");
const UnSubscribe = () => import("@/views/pages/UnSubscribe");
const demo = () => import("@/views/pages/DemoTalentFind");
const DemoDOH = () => import("@/views/pages/DemoDOH/JobBoard");
const DemoDOHJobDetails = () => import("@/views/pages/DemoDOH/JobDetail");
const DemoDOHPQRCheck = () => import("@/views/pages/DemoDOH/PQRCheck");
const ListOrganisation = () => import("@/views/pages/ListOrganisation");
const ChangePassword = () => import("@/pages/ChangePassword");
const AddRecruiters = () => import("@/pages/TeamMembers/AddRecruiters");
const ListRecruiters = () => import("@/pages/TeamMembers/ListRecruiter");
const ConfigPublicJobBoard = () => import("@/pages/ConfigPublicJobBoard");
const AddPublicJobExternal = () => import("@/pages/JobIssuer/AddPublicJobExternal");
const JobIssuerHome = () => import("@/pages/JobIssuer/JobIssuerHome");
const EditPublicJobExternal = () => import("@/pages/JobIssuer/AddPublicJobExternal");
const DocumentLibrary = () => import("@/pages/DocumentLibrary");
const LogoAndBanner = () => import("@/pages/LogoAndBanner");
const InterviewEvaluationForm = () => import("@/pages/ActivityList/CandidateInterviewer/InterviewEvaluationForm");
//TFW
const TFWLandingPage = () => import("@/pages/TFW/LandingPage/LandingPage");
const TFWCandidateRegistration = () => import("@/pages/TFW/CandidateRegistration");
const TFWLogin = () => import("@/pages/TFW/Login");
const TFWOrganisationRegistration = () => import("@/pages/TFW/OrganisationRegistration");
const TFWPartnerProfile = () => import("@/pages/TFW/PartnerProfile");
const TFWPartnerProfileView = () => import("@/pages/TFW/PartnerProfileView");
const TFWPartnerProfileList = () => import("@/pages/TFW/PartnerList");
const TFWLanguageCourse = () => import("@/pages/TFW/LanguageCourse");
const TFWLanguageCourseList = () => import("@/pages/TFW/StaticCourseList");
const TFWPlacements = () => import("@/pages/TFW/Placements.vue");
const TFWPlacementsView = () => import("@/pages/TFW/PlacementsView.vue");
const TempRegistration = () => import("@/pages/TFW/TempRegistration.vue");
const TFWPartnerView = () => import("@/pages/TFW/PartnerView");
const AddCustomerTemplate = () => import("@/pages/TFW/AddCustomerTemplate");

// Routers
import { Role, getScope, getRoleScopes, LOGIN_URL, getUserRoleScopes, isMobile, getLocalOrgDetail, getCustomerType } from "@/helpers/helper";
import { CONSTANT } from "../helpers/helper";

Vue.use(Router);

const router = new Router({
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: (to, from, savedPosition) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 });
      }, 500);
    });
  },
  routes: configRoutes(),
});

router.beforeEach((to, from, next) => {
  const { authorize } = to.meta;
  if (
    !localStorage.getItem("userData") &&
    !to.path.includes("/auth") &&
    !to.path.includes("/faq") &&
    !to.path.includes("/public/job-board") &&
    !to.path.includes("/public/job-board-detail/:id") &&
    !to.path.includes("/demo") &&
    !to.path.includes("/doh/pqr-check") &&
    !to.path.includes("/register") &&
    !to.path.includes("/forgot") &&
    !to.path.includes("/unsubscribe") &&
    !to.path.includes("/ief") &&
    !to.path.includes("/tfw") &&
    !to.path.includes("/list-organisation") &&
    !to.path
  ) {
    next(LOGIN_URL());
  }
  if (!localStorage.getItem("userData") && to.path == "/demo/doh") {
    next("/demo/doh/8");
  }
  if (localStorage.getItem("userData") && to.path.includes("/auth")) {
    if (
      getScope() === "customer_candidate" ||
      getScope() === "system_candidate"
    ) {
      next("/home");
    } else if (getScope() === "customer_user") {
      if (getUserRoleScopes().length && getUserRoleScopes()[0]?.id === 6)
        next("/management-report");
      else
        next("/list-activity")
    } else if (getLocalOrgDetail()?.customer_type_id === 1) {
      next("/candidate-list/super_customer");
    } else if (getCustomerType() === CONSTANT.CUSTOMER_TYPES.language_school) {
      next("/spc-dashboard")
    } else {
      next("/candidate-list");
    }
  }
  if (authorize && authorize.length) {
    const currentUserRole = getScope();
    if (!authorize.includes(currentUserRole)) {
      return next(LOGIN_URL());
    } else {
      return next();
    }
  }
  if (isMobile() === true && to?.path?.includes("/public/job-board") && to?.query?.job_id) {
    next(`/public/job-board-detail/${to?.query?.job_id}`)
  }
  if (to?.path?.includes("/public/job-board") && getScope() && !["customer_candidate", "system_candidate"].includes(getScope())) {
    next(`/jobs-list/status/active`);
  }
  next();
});

export default router;

function configRoutes() {
  return [
    {
      path: "/",
      redirect: () => {
        if (localStorage.getItem("userData")) {
          if (
            getScope() === "customer_candidate" ||
            getScope() === "system_candidate"
          ) {
            return "/home";
          } else if (getLocalOrgDetail()?.customer_type_id === 1) {
            return "/candidate-list/super_customer";
          } else if (
            getCustomerType() === CONSTANT.CUSTOMER_TYPES.language_school
          ) {
            return "/spc-dashboard";
          } else {
            return "/candidate-list";
          }
        } else {
          return "/";
        }
      },
      name: "Home",
      component: TheContainer,
      children: [
        {
          path: "candidate-list",
          name: "Candidates",
          component: NewCandidateList,
          beforeEnter(from, to, next) {
            if (
              getScope() === "customer_candidate" ||
              getScope() === "system_candidate"
            ) {
              next("/home");
            } else {
              next();
            }
          },
          children: [
            {
              path: ":customer_type",
              name: "TFWCandidates",
              component: NewCandidateList,
            },
          ],
          meta: {
            authorize: [
              Role.systemAdmin,
              Role.customerAdmin,
              Role.customerRecruiter,
            ],
          },
        },
        {
          path: "job-candidates-status",
          name: "Job Candidates Status",
          component: JobCandidatesStatus,
          meta: {
            authorize: [Role.customerAdmin, Role.customerRecruiter],
          },
        },
        {
          path: "add-new-candidate",
          name: "AddCandidate",
          component: NewAddCandidate,
          // meta: {
          //   authorize: [
          //     Role.systemAdmin,
          //     Role.customerAdmin,
          //     Role.customerRecruiter,
          //   ],
          // },
        },
        {
          path: "profile",
          name: "CandidateProfile",
          component: CandidateProfile,
          meta: {
            authorize: [
              Role.systemAdmin,
              Role.customerAdmin,
              Role.customerRecruiter,
              Role.systemCandidate,
              Role.customerCandidate,
            ],
          },
        },
        {
          path: "add-hospital",
          name: "AddHospital",
          component: AddHospital,
          meta: {
            authorize: [Role.systemAdmin],
          },
        },
        {
          path: "hospital-admin-list",
          name: "HospitalAdminList",
          component: HospitalAdminList,
          meta: {
            authorize: [Role.systemAdmin],
          },
        },
        {
          path: "hospital-list",
          name: "HospitalList",
          component: HospitalList,
          meta: {
            authorize: [Role.systemAdmin],
          },
        },
        {
          path: "candidate-list-old",
          name: "OldCandidateList",
          component: CandidateList,
        },
        {
          path: "add-hospital-admin",
          name: "AddHospitalAdmin",
          component: AddHospitalAdmin,
          meta: {
            authorize: [Role.systemAdmin],
          },
        },
        {
          path: "add-hospital-recruiters",
          name: "AddHospitalRecruiters",
          component: AddHospitalRecruiters,
          meta: {
            authorize: [Role.customerAdmin],
          },
        },
        {
          path: "hospital-recruiters-list",
          name: "HospitalRecruitersList",
          component: HospitalRecruitersList,
          meta: {
            authorize: [Role.customerAdmin],
          },
        },
        {
          path: "job-board",
          name: "Job Board",
          component: JobBoard,
          meta: {
            authorize: [
              Role.systemAdmin,
              Role.customerAdmin,
              Role.customerRecruiter,
            ],
          },
        },
        {
          path: "job-board-detail/:id",
          name: "Job board detail",
          component: JobBoardDetail,
          meta: {
            authorize: [
              Role.systemAdmin,
              Role.customerAdmin,
              Role.customerRecruiter,
            ],
          },
        },
        {
          path: "dashboard",
          component: Dashboard,
          meta: {
            authorize: [
              Role.systemAdmin,
              Role.customerAdmin,
              Role.customerRecruiter,
            ],
          },
        },
        {
          path: "home",
          name: "Candidate Home",
          component: Home,
          meta: {
            authorize: [Role.systemCandidate, Role.customerCandidate],
          },
        },
        {
          path: "candidate/:id",
          name: "Candidate",
          component: CandidateDetails,
          children: [
            {
              path: ":customer_type",
              name: "TFWCandidate",
              component: CandidateDetails,
            },
          ],
          meta: {
            authorize: [
              Role.systemCandidate,
              Role.customerCandidate,
              Role.systemAdmin,
              Role.customerAdmin,
              Role.customerRecruiter,
              Role.customerUser,
            ],
          },
        },
        {
          path: "add-facility",
          name: "AddFacility",
          component: AddFacility,
          meta: {
            authorize: [Role.systemAdmin],
          },
        },
        {
          path: "facility-list",
          name: "FacilityList",
          component: FacilityList,
          meta: {
            authorize: [Role.systemAdmin],
          },
        },
        {
          path: "facility-detail",
          name: "FacilityDetail",
          component: AddFacilityDetail,
          meta: {
            authorize: [Role.customerAdmin],
          },
        },
        {
          path: "add-facilityuser",
          name: "AddFacilityUser",
          component: AddFacilityUser,
          meta: {
            authorize: [Role.customerAdmin],
          },
        },
        {
          path: "add-customer",
          name: "AddCustomer",
          component: AddCustomer,
          meta: {
            authorize: [Role.systemAdmin],
          },
        },
        {
          path: "customer-list",
          name: "CustomerList",
          component: CustomerList,
          meta: {
            authorize: [Role.systemAdmin],
          },
        },
        {
          path: "jobs-list",
          name: "JobList",
          component: ListJob,
          meta: {
            authorize: [
              Role.systemAdmin,
              Role.systemRecruiter,
              Role.customerAdmin,
              Role.customerRecruiter,
              Role.customerCandidate,
              Role.systemCandidate,
            ],
          },
          children: [
            {
              path: "status/:jobStatus",
              name: "JobBoardByStatus",
              component: ListJob,
              meta: {
                authorize: [
                  Role.systemAdmin,
                  Role.systemRecruiter,
                  Role.customerAdmin,
                  Role.customerRecruiter,
                ],
              },
            },
          ],
        },
        {
          path: "job-list",
          name: "JobListNew",
          component: ListJobNew,
          meta: {
            authorize: [
              Role.systemAdmin,
              Role.systemRecruiter,
              Role.customerAdmin,
              Role.customerRecruiter,
            ],
          },
        },
        {
          path: "job-list-detail/:id",
          name: "JobListDetail",
          component: JobListDetail,
          authorize: [
            Role.systemAdmin,
            Role.systemRecruiter,
            Role.customerAdmin,
            Role.customerRecruiter,
          ],
        },

        {
          path: "add-job",
          name: "AddJobs",
          component: AddJobs,
          meta: {
            authorize: [
              Role.systemRecruiter,
              Role.customerAdmin,
              Role.customerRecruiter,
            ],
          },
        },
        {
          path: "edit-job/:id",
          name: "EditJob",
          component: EditJob,
          authorize: [
            Role.systemRecruiter,
            Role.customerAdmin,
            Role.customerRecruiter,
          ],
        },
        {
          path: "job-allocation",
          name: "JobAllocation",
          component: JobAllocation,
        },
        {
          path: "404",
          name: "Page404",
          component: Page404,
        },
        {
          path: "/public/job-board/:cus_id",
          name: "Public Job Board",
          component: JobBoard,
          children: [
            {
              path: "org/:org_id",
              component: JobBoard,
              name: "Public Org Based Job Board",
            },
          ],
        },
        {
          path: "/public/job-board-detail/:id",
          name: "Public Job Board Detail",
          component: JobBoardDetail,
        },
        {
          path: "recruiter-dashboard",
          name: "Recruiter DashBoard",
          component: RecruiterDashBoard,
          meta: {
            authorize: [
              Role.systemAdmin,
              Role.systemRecruiter,
              Role.customerAdmin,
              Role.customerRecruiter,
            ],
          },
        },
        {
          path: "job-list-preview/:id",
          name: "Job List Preview",
          component: JobListPreview,
          authorize: [
            Role.systemAdmin,
            Role.systemRecruiter,
            Role.customerAdmin,
            Role.customerRecruiter,
            Role.customerUser,
          ],
        },
        {
          path: "change-password",
          name: "Change Password",
          component: ChangePassword,
        },
        {
          path: "add-organisation",
          name: "Add Organisation",
          component: AddOrganisation,
          meta: {
            authorize: [Role.customerAdmin, Role.systemAdmin],
          },
        },
        {
          path: "add-child-organisation/customer/:customer_uid/org/:parent_org_id",
          name: "Add Child Organisation",
          component: AddChildOrganisation,
          meta: {
            authorize: [Role.customerAdmin, Role.systemAdmin],
          },
        },
        {
          path: "interview-evaluation-form/job/:job_id/candidate/:candidate_uid/action_detail/:action_detail_id",
          name: "Interview Evaluation Form",
          component: InterviewEvaluationForm,
          meta: {
            authorize: [
              Role.customerUser,
              Role.customerRecruiter,
              Role.customerAdmin,
            ],
          },
        },
        {
          path: "organisation-list",
          name: "List Organisation",
          component: OrganisationList,
          meta: {
            authorize: [Role.customerAdmin],
          },
        },
        {
          path: "add-contactuser",
          name: "Add Contact User",
          component: AddContactUser,
          meta: {
            authorize: [Role.customerAdmin],
          },
        },
        {
          path: "list-contactuser",
          name: "List Contact User",
          component: ContactUserList,
          meta: {
            authorize: [Role.customerAdmin],
          },
        },
        {
          path: "list-activity",
          name: "List Activity",
          component: ActivityList,
          meta: {
            authorize: [
              Role.customerUser,
              Role.customerRecruiter,
              Role.customerAdmin,
            ],
          },
        },
        {
          path: "candidate-reviewer-activity-review/job/:jobID/candidate/:candidateID",
          name: "Candidate Review Activity",
          component: CandidateReviewerReviewActivity,
          meta: {
            authorize: [
              Role.customerUser,
              Role.customerRecruiter,
              Role.customerAdmin,
            ],
          },
        },
        {
          path: "job-authoriser-activity-review/:id",
          name: "Job Review Activity",
          component: JobAuthoriserReviewActivity,
          meta: {
            authorize: [
              Role.customerUser,
              Role.customerRecruiter,
              Role.customerAdmin,
            ],
          },
        },
        {
          path: "candidate-interviewer-activity-review/job/:jobID/candidate/:candidateID",
          name: "Candidate Interview Review Activity",
          component: CandidateInterviewerReviewActivity,
          meta: {
            authorize: [
              Role.customerUser,
              Role.customerRecruiter,
              Role.customerAdmin,
            ],
          },
        },
        {
          path: "candidate-interviewer-activity-interview/job/:jobID/candidate/:candidateID",
          name: "Candidate Interviewer Interview Activity",
          component: CandidateInterviewerInterviewActivity,
          meta: {
            authorize: [
              Role.customerUser,
              Role.customerRecruiter,
              Role.customerAdmin,
            ],
          },
        },
        {
          path: "candidate-schedule-interview/job/:jobID/candidate/:candidateID",
          name: "Candidate Schedule Interview",
          component: CandidateScheduleInterview,
          meta: {
            authorize: [
              Role.systemCandidate,
              Role.customerCandidate,
              Role.customerAdmin,
            ],
          },
        },
        {
          path: "email-messages",
          name: "Recruiter Email Messages",
          component: RecruiterEmailMessages,
          meta: {
            authorize: [
              Role.systemAdmin,
              Role.systemRecruiter,
              Role.customerAdmin,
              Role.customerRecruiter,
            ],
          },
        },
        {
          path: "/create-new-recruiter",
          name: "Create New Recruiters",
          component: AddRecruiters,
          meta: {
            authorize: [
              Role.systemAdmin,
              Role.customerAdmin,
              Role.customerRecruiter,
            ],
          },
        },
        {
          path: "/list-recruiter",
          name: "list recruiters",
          component: ListRecruiters,
          meta: {
            authorize: [
              Role.systemAdmin,
              Role.customerAdmin,
              Role.customerRecruiter,
            ],
          },
        },
        {
          path: "config-public-job-board",
          name: "Config Public Job Board",
          component: ConfigPublicJobBoard,
          meta: {
            authorize: [Role.customerAdmin],
          },
        },
        {
          path: "theme-and-logo",
          name: "Config Theme and Logo",
          component: LogoAndBanner,
          meta: {
            authorize: [Role.customerAdmin],
          },
        },
        {
          path: "email-templates",
          name: "Email Templates",
          component: EmailConfiguration,
          meta: {
            authorize: [Role.customerAdmin],
          },
        },
        {
          path: "campaign-register",
          name: "Campaign Register",
          component: CampaignRegister,
          meta: {
            authorize: [Role.customerAdmin, Role.customerRecruiter],
          },
        },
        {
          path: "campaign-form/:campaign_uuid",
          name: "Campagin Form",
          component: CampaignForm,
          meta: {
            authorize: [Role.customerAdmin, Role.customerRecruiter],
          },
        },
        {
          path: "onboarding-documents",
          name: "Document Library",
          component: DocumentLibrary,
          meta: {
            authorize: [Role.customerAdmin, Role.customerRecruiter],
          },
        },
        {
          path: "list-job-templates",
          name: "List Job Templates",
          component: ListJobTemplates,
          meta: {
            authorize: [Role.customerAdmin, Role.customerRecruiter],
          },
        },
        {
          path: "upload-org-pictures",
          name: "Upload Organisation Pictures",
          component: UploadOrganisationPictures,
          meta: {
            authorize: [Role.customerAdmin, Role.customerRecruiter],
          },
        },
        {
          path: "notification-config",
          name: "Notification Configuration",
          component: NotificationConfiguration,
          meta: {
            authorize: [Role.customerAdmin, Role.customerRecruiter],
          },
        },
        {
          path: "management-report",
          name: "Management Report",
          component: ManagementReport,
          meta: {
            authorize: [
              Role.customerAdmin,
              Role.customerRecruiter,
              Role.customerUser,
            ],
          },
        },
        {
          path: "spc-dashboard",
          name: "SPC Dashboard",
          component: SPCDashboard,
          meta: {
            authorize: [Role.customerAdmin],
          },
        },
        {
          path: "add-course",
          name: "Add Course",
          component: AddCourse,
          meta: {
            authorize: [Role.customerAdmin],
          },
        },
        {
          path: "list-course",
          name: "List Course",
          component: ListCourse,
          meta: {
            authorize: [
              Role.customerAdmin,
              Role.systemCandidate,
              Role.customerCandidate,
            ],
          },
        },
        {
          path: "course/:id",
          name: "View Course",
          component: ViewCourse,
          meta: {
            authorize: [Role.customerAdmin],
          },
        },
        {
          path: "ai-template/create",
          name: "AI Create Template",
          component: AITemplate,
        },
        {
          path: "add-course-template/:course_id",
          name: "Add Course Template",
          component: AddCourseTemplate,
          meta: {
            authorize: [Role.customerAdmin],
          },
        },
        {
          path: "profile-page",
          name: "Create Profile Page",
          component: CreateProfilePage,
          meta: {
            authorize: [Role.customerAdmin],
          },
        },
        {
          path: "/ief/:org_name/:ex_hash_id/action/:action_hash_id",
          name: "External IEF",
          component: InterviewEvaluationForm,
        },
        {
          path: "/tfw/login",
          name: "TFW Login",
          component: TFWLogin,
          beforeEnter: beforeEnterTFW,
        },
        {
          path: "/tfw/registration/candidate",
          name: "TFW Candidate Registration",
          component: TFWCandidateRegistration,
          beforeEnter: beforeEnterTFW,
        },
        {
          path: "/tfw/registration/organisation",
          name: "TFW Organisation Registration",
          component: TFWOrganisationRegistration,
          beforeEnter: beforeEnterTFW,
        },
        {
          path: "/tfw/partner/profile",
          name: "TFW Partner Profile",
          component: TFWPartnerProfileList,
          beforeEnter: beforeEnterTFW,
        },
        {
          path: "/tfw/partner/:id/profile",
          name: "TFW Partner Profile Info",
          component: TFWPartnerProfile,
          beforeEnter: beforeEnterTFW,
        },
        {
          path: "/tfw/partner/:id/view",
          name: "TFW Partner Profile View",
          component: TFWPartnerProfileView,
          beforeEnter: beforeEnterTFW,
        },
        {
          path: "/tfw/course",
          name: "TFW Partner Course",
          component: TFWLanguageCourseList,
          beforeEnter: beforeEnterTFW,
        },
        {
          path: "/tfw/placements",
          name: "TFW Placements",
          component: TFWPlacements,
          beforeEnter: beforeEnterTFW,
        },
        {
          path: "/tfw/placements/view",
          name: "TFW Placements View",
          component: TFWPlacementsView,
          beforeEnter: beforeEnterTFW,
        },
        {
          path: "/:org_name/signup",
          name: "Temp Registration",
          component: TempRegistration,
          beforeEnter: beforeEnterTFW,
        },
        {
          path: "/tfw/partner/view-profile/:id",
          name: "TFWPartnerView",
          component: TFWPartnerView,
        },
        {
          path: "/customer_template",
          name: "AddCustomerTemplate",
          component: AddCustomerTemplate,
        },
        {
          path: "/tfw/course/:id",
          name: "TFW Partner Course Info",
          component: TFWLanguageCourse,
          beforeEnter: beforeEnterTFW,
        },
        {
          path: "/tfw/job-board/:cus_id",
          name: "TFW Job Board",
          component: JobBoard,
          children: [
            {
              path: ":country_id",
              name: "TFW Job Board Country",
              component: JobBoard,
            },
          ],
        },
        {
          path: "/tfw/job-board-detail/:id",
          name: "TFW Job Board Detail",
          component: JobBoardDetail,
        },
        {
          path: "/tfw",
          name: "TFW",
          component: TFWLandingPage,
          beforeEnter: beforeEnterTFW,
        },
        {
          path: "/list-organisation",
          name: "ListOrganisation",
          component: ListOrganisation,
        },
        {
          path: "/:org_name/campaign/:campaign_id",
          name: "RegisterCampaign",
          component: RegisterCampaign,
          beforeEnter: beforeEnterCampaign,
        },
        {
          path: "/language-score",
          name: "LanguageScore",
          component: LanguageScore,
        },
        {
          path: "/assessment/:assessment_id",
          name: "Assessment",
          component: Assessment,
        },
        {
          path: "/add-candidate",
          name: "AddCandidate",
          component: AddCandidate,
        },
        {
          path: "/stm/tfw/job-board/:cus_id",
          name: "TFM Job Board STM",
          component: JobBoardStm,
          children: [
            {
              path: ":country_id",
              name: "TFM Job Board Country STM",
              component: JobBoardStm,
            },
          ],
        },
        {
          path: "",
          name: "Direct Login",
          component: Login,
          beforeEnter: (to, from, next) => {
            if (
              getScope() === "customer_candidate" ||
              getScope() === "system_candidate"
            ) {
              next({ name: "Candidate Home" });
            } else if (getScope() === "customer_user") {
              if (getUserRoleScopes.length && getUserRoleScopes[0]?.id === 6)
                next("/management-report");
              else next("/list-activity");
            } else if (
              getCustomerType() == CONSTANT.CUSTOMER_TYPES.language_school
            ) {
              next("/spc-dashboard");
            } else if (getScope()) {
              next({ name: "Candidates" }); // path /candidate-list
            }
            next();
          },
        },
        {
          path: "/faq",
          name: "Frequently Asked Questions",
          component: faq,
        },
        {
          path: "/:org_name",
          name: "Org Based Login",
          component: Login,
          beforeEnter: (to, from, next) => {
            let pre_defined_paths = {
              "/faq": "Frequently Asked Questions",
              "/demo": "Demo TalentFind",
              "/forgot": "Direct Forgot",
            }; // need to be entered manually if new page added outside the getScope() to avoid routing to login page
            if (to.path.includes("/faq")) {
              next({ name: pre_defined_paths[to.path] });
            } else if (
              getScope() === "customer_candidate" ||
              getScope() === "system_candidate"
            ) {
              next({ name: "Candidate Home" });
            } else if (getScope() === "customer_user") {
              if (
                getUserRoleScopes().length &&
                getUserRoleScopes()[0]?.id === 6
              )
                next("/management-report");
              else next("/list-activity");
            } else if (
              getCustomerType() == CONSTANT.CUSTOMER_TYPES.language_school
            ) {
              next("/spc-dashboard");
            } else if (getScope()) {
              next({ name: "Candidates" }); // path /candidate-list
            } else if (
              to.path.includes("/demo") ||
              to.path.includes("/forgot")
            ) {
              next({ name: pre_defined_paths[to.path] });
            }
            next();
          },
        },
        {
          path: "/:org_name/register/:id",
          component: Register,
          props: true,
          children: [
            {
              path: "",
              component: Register,
              name: "Register",
            },
            {
              path: "organisation/:org_id",
              component: Register,
              name: "Organisation Register",
            },
            {
              path: "organisation/:org_id/location/:loc_id",
              component: Register,
              name: "Organisation with Location Register",
            },
            {
              path: "code/:code",
              component: RegisterViaMail,
              name: "Candidate With Email Register",
            },
          ],
        },
      ],
    },
    {
      path: "/:org_name/unsubscribe",
      name: "UnSubscribe",
      component: UnSubscribe,
    },
    {
      path: "/forgot",
      name: "Direct Forgot",
      component: Forgot,
    },
    {
      path: "/:org_name/forgot",
      name: "Organisation Based Forgot",
      component: Forgot,
    },
    {
      path: "/auth",
      redirect: "/auth/404",
      name: "Pages",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "404",
          name: "AuthPage404",
          component: Page404,
        },
        {
          path: "500",
          name: "Page500",
          component: Page500,
        },
        {
          path: "login",
          name: "Login",
          component: Login,
        },
        {
          path: "reset-password",
          name: "Reset",
          component: Reset,
        },
      ],
    },

    {
      path: "/demo",
      name: "Demo TalentFind",
      component: demo,
    },
    {
      path: "/demo/doh/:id",
      name: "Demo DOH",
      component: DemoDOH,
    },
    {
      path: "/doh/public/job-board-detail/:id",
      name: "Demo DOH JOB BOARD",
      component: DemoDOHJobDetails,
    },
    {
      path: "/doh/pqr-check",
      name: "Demo DOH PQR CHECK",
      component: DemoDOHPQRCheck,
    },
    {
      path: "/add/public/job",
      name: "Add Public Jobs to Job Board",
      component: AddPublicJobExternal,
      meta: {
        authorize: [Role.customerUser],
      },
    },
    {
      path: "/public/job/home",
      name: "Job Issuer Home",
      component: JobIssuerHome,
      meta: {
        authorize: [Role.customerUser],
      },
    },
    {
      path: "/edit/public/job/:id",
      name: "Edit Public Job by Job Issuer",
      component: EditPublicJobExternal,
      meta: {
        authorize: [Role.customerUser],
      },
    },
  ];
}

function beforeEnterTFW(to, from, next) {
  if (!to.path.includes("/tfw/partner")) {
    if (localStorage.getItem("userData") && getLocalOrgDetail()?.customer_type_id !== 1
      && getLocalOrgDetail()?.customer_type_id !== 5) {
      next("/candidate-list")
    }
    if (
      getScope() === "customer_admin"
    ) {
      next("/candidate-list")
    }
    if (getLocalOrgDetail()?.customer_type_id !== CONSTANT.CUSTOMER_TYPES.educational_institute) {
      if (
        getScope() === "customer_candidate" ||
        getScope() === "system_candidate"
      ) {
        next({ name: 'Candidate Home' })
      }
    }
  }
  next()
}

function beforeEnterCampaign(to, from, next) {
  if (from.path.includes("/register")) {
    window.location.reload();
  }
  next()
}